import React from "react";
import { Container, Row, Col } from "react-bootstrap";

function Footer() {
  return (
    <Container fluid className="footer">
      <Row>
        <Col md="4" className="footer-copywright">
          <h3>This website is maintained by Sharan Kumar.</h3>
        </Col>
        <span id="siteseal"><script async type="text/javascript" src="https://seal.godaddy.com/getSeal?sealID=qZIcmbSjVT6kF9g2YbjBd482dOsi4cgJZhFlWdGpfQ9UeHK5ZzU5AABnOQa3"></script></span>
      </Row>
    </Container>
  );
}

export default Footer;
