import React from "react";
import Card from "react-bootstrap/Card";
//import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Sharan Kumar </span>
            <br />I have graduated as <span class="purple">Bachelor of Engineering (B.E)</span> in <span class="purple"> Computer Science Engineering</span>. 
            <br />
            I have experience over web developing and always love to learn new.
            </p>
            <br />
            <br />
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
